<template>
  <div class="container mx-auto min-h-80">
    <div class="col-constructor-name">
      <font size="+3">Impressum</font>
      <br>
      <br>
    <strong>jung radiology GmbH</strong>
      <br>
      Health Innovation Port (Philips Campus)
      <br>
      Röntgenstraße 24
      <br>
      D-22335 Hamburg
      <br>
      <br>
      <p>
        Tel.: +49 (0) 40 / 328 907 93 - 9
        <br>
        Fax: +49 (0) 40 / 328 907 93 - 1
        <br>
        Email: info@jung-radiology.de
      </p>
      <br>
      <br>
      Handelsregister Hamburg, HR B 11 000 8
      <br>
      <br>
      Steuer-Nr.: 49 / 735 / 01009
      UID-Nr.: DE 265 89 34 36
      <br>
      <br>
      Geschäftsführer: Dr. Lothar Spies
      <br>
      <br>
      Inhaltlich Verantwortlicher:
      Dr. Lothar Spies
      <br>
      <br>
      <font size="+2">Rechtliche Hinweise</font>
      <br>
      <br>
      <strong>Haftung</strong>
      <p>Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität kann jedoch nicht übernommen werden.</p>
      <br>
      <p>Wir haben auf den Seiten unserer Website Links zu Seiten anderer Anbieter gesetzt. jung radiology GmbH erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung der Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat jung radiology GmbH keinerlei Einfluss. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
      <br>
      <p>jung radiology GmbH schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen, aus.</p>
      <br>
      <strong>Copyright:</strong>
      jung radiology GmbH
      <br>
      <br>
    </div>
  </div>

</template>

<script>
export default {
  name: 'impressum',
  }
</script>

<style>
.home-container {
  text-align: center;
}
</style>
