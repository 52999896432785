import axios from 'axios'

<template>

  <div class="login-form md-ml-0 sm-ml-0 ml-8">
    <div v-show="loginError" class="alert">
      <span>Wrong username or password. Please try again.</span>
    </div>
    <form>
      <div class="row">
        <label for="username">Username</label>
        <input required autofocus v-model="username" type="text" id="username" name="username"/>
        </div>
      <div class="row">
        <label for="password">Password</label>
        <input required v-model="password" type="password" id="password" name="password"/>
      </div>
      <div class="row">
        <label for="login" content=" "></label>
        <button id="login" type="submit" @click="handleSubmit" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm">Login</button>
      </div>
    </form>
  </div>

</template>

<script>
import {Storage} from "@/Storage";

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      loginError: false,
      loading: false,
      user:{
        passwordTries: 0
      }

    }
  },
  methods: {
    handleSubmit(e) {
      this.loginError = false;
      this.loading = true;
      e.preventDefault()
      if (this.password.length > 0) {
        this.$http.post('/login', {
          username: this.username,
          password: this.password
        }).then(response => {
          // let is_admin = response.data.user.is_admin
          console.log(response);

          if (response.data) {
            Storage.updateData(response.data, this.$root)

            let accessToken = response.data.access_token;
            let username = response.data.username;
            let info = Storage.decodeJwt(accessToken)

            console.log("info.darkMode")
            console.log(info.darkMode)
            console.log(info)
            // this.$store.commit("user/setDarkMode", info.darkMode)

            this.$store.commit('user/setUser', {
              username: username,
              name: info.name,
              darkMode: info.darkMode,
              roles: info.roles
            })

          }

          if (Storage.isAuthenticated() || response.status === 200) {
            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl)
            } else {
              this.$router.push({name: 'home'})
            }
          }

        }).catch(error => {
          this.handleUnauthorized(this.username)
          console.log(error);
          console.log(error.response);
          this.loginError = true;
        });

        this.loading = false;
      }
    },
    handleUnauthorized(username){
      console.log(username)
      this.$http
          .get('/api/v1/login/' + username)
          .then(response => {
            console.log("here")
            console.log("this is response " + response)
            this.user.passwordTries = response.data
            if(response.data < 6){
              alert("Das Passwort ist inkorrekt. Sie haben das Passwort bereits" +" " + this.user.passwordTries +" " +"Mal falsch eingegeben.")
            }
            else{
              alert("Sie haben das Passwort bereits zu oft falsch eingegeben. Ihr Account ist nun gesperrt. Bitte wenden Sie sich an einen Administrator, um Ihren Account wieder freizugeben.")
            }

          })
          .catch(reason => {
            console.log("this is reason " + reason)
            alert("Der Nutzername existiert nicht.")
          })
      console.log("here2")
    },

    logout() {
      return new Promise((resolve) => {
        // TODO: commit(AUTH_LOGOUT) to backend
        Storage.clearTokens()
        resolve()
      })
    }
  }
}
</script>

<style scoped>
  .login-form{
    margin: 2rem auto;
  }

  label {
    width: 8rem;
    display: inline-block;
  }

  .row {
    margin: 0.5rem auto;
  }
</style>